import aboutThree01 from "@/images/about/aldrin.jpg"
import aboutThree02 from "@/images/about/avin.png"

export const AboutThreeData = {
  teamContent01: {
    title: "Aldrin Lenny",
    subTitle: "Co-Founder",
    text: "Extremely passionate about technology and how it solves real world problems in a way that scales, Aldrin has been part of various fortune 500 companies in and around the globe during his 20+ years in the IT field.He has worked extensively with companies in the United States, India, New Zealand and Australia over his long career and has been part of developing apps that have crossed millions in downloads in the SportsTech category in the past. Now he calls New Zealand his home and commutes between different continents providing cloud-based consulting solutions.",
  },

  TeamImg1: [aboutThree01],

  teamContent02: {
    title: "Avin Leo",
    subTitle: "Co-Founder",
    text: "A seasoned Rotarian and boss of a medium sized consulting company in Kochi, Kerala, Avin works on building cloud-based solutions for Hospitality and SportsTech industries. He enjoys spending time with the Cochin Harbour Rotary teams in organizing and executing highly useful community-oriented amenities and services.He has in the past, helped build end-to-end solutions for Automotive Logistics, Hopsitality POS systems and services using cloud and mobile technologies. ",
  },

  TeamImg2: [aboutThree02],
}
