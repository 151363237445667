import React from "react"
import { AboutThreeData } from "./data"
import { Col, Container, Row } from "react-bootstrap"

const AboutThree = () => {
  const { teamContent01, teamContent02, TeamImg1, TeamImg2 } = AboutThreeData
  return (
    <section className="team-section">
      <h2 className="about-sec_title">Our core team</h2>
      <Container>
        <Row className="MB_45">
          <Col lg={3} md={6} sm={12}>
            <div className="ab_img">
              <h2 className="sec_title">{teamContent01.title}</h2>
              <h4 className="sub_title">{teamContent01.subTitle}</h4>
              <img className="team_img" src={TeamImg1} alt="" />
            </div>
          </Col>
          <Col lg={9} md={6} sm={12} className="team-cont-div">
            <p className="sec_desc mt-25">{teamContent01.text}</p>
          </Col>
        </Row>
        <Row className="MB_45">
          <Col lg={9} md={6} sm={12} className="team-cont-div ord2">
            <p className="sec_desc mt-25">{teamContent02.text}</p>
          </Col>
          <Col lg={3} md={6} sm={12} className="ord1">
            <div className="ab_img">
              <h2 className="sec_title">{teamContent02.title}</h2>
              <h4 className="sub_title">{teamContent02.subTitle}</h4>
              <img className="team_img" src={TeamImg2} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutThree
