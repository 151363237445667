import React from "react"
import { AboutFourData } from "./data"
import { navigate } from "gatsby"

const AboutFour = () => {
  const { sectionContent, gallery, investor } = AboutFourData
  return (
    <section className="commonSection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <h2 className="support-cast"> {sectionContent.title} </h2>
            <img className="support-cast-img" src={gallery} alt="" />
            <button
              className="about-btn"
              type="submit"
              id="con_submit"
              onClick={() => {
                navigate("/contact")
              }}
            >
              <span>Join Our Team</span>
            </button>
          </div>
          <div className="col-lg-12 col-sm-12 investor-sec">
            <h2 className="support-cast"> {sectionContent.partner_title} </h2>
            <img src={investor} alt="" className="investor-img" />
            <h2 className="investor-name"> {sectionContent.partner_name} </h2>
            <button
              className="about-btn"
              type="submit"
              id="con_submit"
              onClick={() => {
                navigate("/contact")
              }}
            >
              <span>Talk to Investor Relations</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutFour
