import React, { Fragment } from "react"
// import AuthorCard from "@/components/author-card";
// import Comments from "@/components/comments";
// import blogDetailsImage from "@/images/blog/7.jpg"

const ContentSection = ({ data }) => {
  return (
    <Fragment>
      <div className="single_blog">
        <div className="blog_headings">
          <h2>Cloud and Mobile native SPORTS LEAGUE MANAGEMENT</h2>
        </div>
        <div className="blog_details">
          <p>
            Our mission is to provide cloud-native and mobile-first solutions
            for grassroot sports clubs/organisations and educational
            institutions so that they can focus on building raw talent within
            their own neighbourhoods and communities.
          </p>
          <p>
            The founders behind scoreblox have immense respect for sporting
            individuals and teams around the world and are willing to provide
            useful scoring and event management tools that are built with
            cutting-edge framework and infrastructure.
          </p>
        </div>
        {/* <div className="blog_tagitems">
          <span>Tags:</span>
          <a href="#">Business</a>, <a href="#">Agency</a>,{" "}
          <a href="#">Digital</a>, <a href="#">Technology</a>
        </div> */}
        {/* <AuthorCard /> */}
        {/* <Comments /> */}
      </div>
    </Fragment>
  )
}

export default ContentSection
