import aboutOne01 from "@/images/team.jpg"
import aboutOne02 from "@/images/investor.png"

export const AboutFourData = {
  sectionContent: {
    title: "Support Cast",
    partner_title: "Partners & Investors ",
    partner_name: "Kawntr Limitd New Zealand ",
  },
  gallery: [aboutOne01],
  investor: [aboutOne02],
}
