import React from "react"
import { Container, Row, Col } from "react-bootstrap"
// import BlogSidebar from "@/components/blog-sidebar";
// import SinglePostCard from "@/components/single-post-card";
// import SidebarOne from "@/components/sidebar-one"
import ContentSection from "@/components/content-section"

import { ContentData } from "./data"

const AboutTwo = () => {
  return (
    <section className="about-main-head">
      <Container>
        <Row>
          <Col lg={12}>
            {/* <SinglePostCard /> */}
            <ContentSection data={ContentData} />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default AboutTwo
