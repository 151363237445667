import React from "react"
import Layout from "@/components/layout"
import PageBanner from "@/components/page-banner"
import AboutFour from "@/components/about-four"
import HeaderOne from "@/components/header-one"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import Footer from "@/components/footer-one"

import AboutTwo from "@/components/about-two"
import AboutThree from "@/components/about-three"

const AboutPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="About Us">
          <HeaderOne />
          <PageBanner title="About scoreblox" name="About" />
          <AboutTwo />
          <AboutThree />
          <AboutFour />
          {/* <PricingPlan /> */}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  )
}

export default AboutPage
