import Feature02 from "@/images/home_1/1.jpg"

export const ContentData = {
  sectionContent: {
    secTitle: "Cricket App Platform",
    image: Feature02,
  },
  contents: [
    {
      subTitle: "made as easy as 1-2-3",
      text: "One Viewing App for cricket - There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
    },
    {
      subTitle: "made as easy as 4-5-6",
      text: "Two Viewing App for cricket - There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
    },
  ],
}

export const SidebarData = {
  sectionOne: {
    title: "Features",
  },
  items: [
    {
      title: "First item",
      iconName: "fa fa-gear",
    },
    {
      title: "Second item",
      iconName: "fa fa-gear",
    },
    {
      title: "Third item",
      iconName: "fa fa-gear",
    },
  ],
}
